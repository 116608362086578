import { Grid, Flex, Spinner, Box } from "@chakra-ui/react";

import { SettingsIcon, DeleteIcon } from "@chakra-ui/icons";
import Payplan from "./Payplan.jsx";
import Account from "./Account.jsx";
import BuyQRCodes from "./BuyQRCodes.jsx";
import { useLocation } from "react-router-dom";
import DeleteAccount from "./DeleteAccount.jsx";
import SystemBar from "../../../components/SystemBar.jsx";
import ChangePassword from "./ChangePassword.jsx";
import ChangeEmail from "./ChangeEmail.jsx";
import TransactionHistory from "./TransactionHistory.jsx";
import { useState, useEffect, useContext } from "react";
import { getUserSubscription } from "../../../calls.js";
import { MyQR } from "../../../components/icons";

import { UserContext } from "../../../context/index.js";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const Profile = () => {
  const { t, i18n } = useTranslation();
  const [state, dispatch] = useContext(UserContext);

  const [toastHasSent, setToastHasSent] = useState(false);

  useEffect(() => {
    const paymentStatus = new URLSearchParams(window.location.search).get(
      "payment"
    );

    // Wait until i18n is ready before showing toast
    const checkTranslations = () => {
      if (i18n.isInitialized) {
        if (!toastHasSent && paymentStatus === "success") {
          toast.success(t("stripeToastSuccess"));
        } else if (paymentStatus === "cancel") {
          toast.error(t("stripeToastCancel"));
        }
        setToastHasSent(true);
      }
    };

    setTimeout(checkTranslations, 1000);

    // Cleanup event listener if needed
    return () => {
      i18n.off("loaded", checkTranslations);
    };
  }, [i18n]);

  const hasFreeRole =
    state?.stripeRole === "early_adopter" ||
    state?.stripeRole === undefined ||
    state?.stripeRole === "free"
      ? true
      : false;

  const navbar = [
    {
      text: t("general"),
      redirect: "",
      extraCSSButton: { fontSize: "14px" },
      icon: {
        active: <SettingsIcon color={"black"} w={"20px"} h={"20px"} />,
        inActive: <SettingsIcon color={"white"} w={"20px"} h={"20px"} />,
      },
    },
    {
      text: t("buyQR"),
      redirect: "kop-qr",
      extraCSSButton: {
        fontSize: "14px",
        display: hasFreeRole ? "flex" : "none",
      },
      icon: {
        active: (
          <Box width={"25px"} height={"25px"}>
            <MyQR color={"black"} width={"20"} height={"10"} />
          </Box>
        ),
        inActive: (
          <Box width={"25px"} height={"25px"}>
            <MyQR color={"white"} width={"10"} height={"10"} />
          </Box>
        ),
      },
    },
    {
      text: t("historik"),
      redirect: "historik",
      extraCSSButton: {
        fontSize: "14px",
      },
      icon: {
        active: (
          <Box width={"25px"} height={"25px"}>
            <MyQR color={"black"} width={"20"} height={"10"} />
          </Box>
        ),
        inActive: (
          <Box width={"25px"} height={"25px"}>
            <MyQR color={"white"} width={"10"} height={"10"} />
          </Box>
        ),
      },
    },
    {
      text: t("removeAccount"),
      redirect: "radera",
      extraCSSContainer: { marginLeft: "auto" },
      extraCSSButton: {
        bg: "#ee3838",
        fontSize: "12px",
        marginLeft: "auto",
      },
      extraCSSText: { color: "white" },
      icon: {
        active: <DeleteIcon color={"white"} w={"20px"} h={"20px"} />,
        inActive: <DeleteIcon color={"white"} w={"20px"} h={"20px"} />,
      },
    },
  ];

  const location = useLocation();
  const [userPlan, setUserPlan] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (userPlan) {
      setLoading(false);
      return;
    }

    if (!state.stripeRole) {
      setLoading(false);
      return;
    }

    const fetchData = async () => {
      try {
        const data = await getUserSubscription(
          () => state.user.getIdToken(),
          state.sub
        );
        setUserPlan({ ...data.data.subscriptionPlan });
      } catch (error) {}
      setLoading(false);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Grid
        color={"white"}
        gap={"10px"}
        padding={"10px 0px"}
        height={"100%"}
        gridTemplateRows={"auto 1fr"}
      >
        <Flex gap={"10px"} h={"fit-content"} fontSize={"14px"}>
          <SystemBar state={state} navbar={navbar} pathname={`/konto`} />
        </Flex>
        {loading ? (
          <Flex
            height={"100%"}
            width={"100%"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Spinner size={"xl"} color={"#062C8E"}></Spinner>
          </Flex>
        ) : (
          <Grid gap={5}>
            {location.pathname === "/konto/updatera-losenord" && (
              <ChangePassword />
            )}
            {location.pathname === "/konto/updatera-email" && <ChangeEmail />}
            {location.pathname === "/konto" && (
              <Account hasFreeRole={hasFreeRole} />
            )}
            {location.pathname === "/konto/historik" && <TransactionHistory />}
            {location.pathname === "/konto/kop-qr" && <BuyQRCodes />}
            {location.pathname === "/konto/radera" && <DeleteAccount />}
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default Profile;
