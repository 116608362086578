import { Box, Flex } from "@chakra-ui/react";
import WidgetWrapper from "./Widgets/WidgetWrapper";
import HighChart from "../functions/toHighChart";
import Select from "react-select";

import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import DatePicker from "./DatePicker";
import { subDays, endOfDay, startOfDay } from "date-fns";

const intervalsOptions = [
  { value: "HOUR", label: "Timme", maxDays: 0, minDays: 0 },
  { value: "DAY", label: "Dag", maxDays: 60, minDays: 1 },
  { value: "WEEK", label: "Vecka", maxDays: 365 * 2, minDays: 14 },
  { value: "MONTH", label: "Månad", maxDays: 365 * 3, minDays: 60 },
  { value: "YEAR", label: "År", maxDays: Infinity, minDays: 365 },
];

const CustomChart = ({ qrSettings, multiple = false, removeLegend = true }) => {
  const { t } = useTranslation();
  const [timespan, setTimespan] = useState(null);
  const [timespanOptions, setTimeSpanOptions] = useState([]);

  const presetIntervals = [
    {
      value: 1,
      label: t("today"),
      calculateRange: () => [startOfDay(new Date()), endOfDay(new Date())],
    },

    {
      value: 7,
      label: t("qrScansLatestX", { days: 7 }),
      calculateRange: () => [subDays(new Date(), 6), new Date()],
    },
    {
      value: 30,
      label: t("qrScansLatestX", { days: 30 }),
      calculateRange: () => [subDays(new Date(), 29), new Date()],
    },
    {
      value: 30 * 6,
      label: t("qrScansLatestXMonth", { month: 6 }),
      calculateRange: () => [subDays(new Date(), 30 * 6 - 1), new Date()],
    },
    {
      value: 365,
      label: t("qrScansLatestXMonth", { month: 12 }),
      calculateRange: () => [subDays(new Date(), 364), new Date()],
    },
  ];

  const [queryOptions, setQueryOptions] = useState({
    interval: intervalsOptions[0].value,
    fromDate: presetIntervals[0].calculateRange()[0].getTime(),
    toDate: presetIntervals[0].calculateRange()[1].getTime(),
  });

  const [intervalOptions, setIntervalOptions] = useState([intervalsOptions[0]]);

  useEffect(() => {
    setTimeSpanOptions(presetIntervals);
    setTimespan(presetIntervals[0]);
  }, [t]);

  const handleTimespanChange = (newTimespan) => {
    setTimespan(newTimespan);
    const [from, to] = newTimespan.calculateRange();
    updateQuery(from.getTime(), to.getTime());
  };

  function calculateDaysBetween(from, to) {
    const fromTimestamp = from;
    const toTimestamp = to;

    const fromDate = new Date(fromTimestamp);
    const toDate = new Date(toTimestamp);

    const timeDifference = toDate.getTime() - fromDate.getTime();
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    return daysDifference;
  }

  function findSuitableInterval(days) {
    for (const interval of intervalsOptions) {
      if (days >= interval.minDays && days <= interval.maxDays) {
        return interval.value;
      }
    }
    return null;
  }

  const updateQuery = (from, to) => {
    const daysbetween = calculateDaysBetween(from, to);

    const customLabel = () => {
      if (from === to) {
        return new Date(from).toLocaleDateString(t("localeCode"), {
          day: "numeric",
          month: "short",
          year: "numeric",
        });
      } else {
        return `${new Date(from).toLocaleDateString(t("localeCode"), {
          day: "numeric",
          month: "short",
          year: "numeric",
        })} ${t("to")} ${new Date(to).toLocaleDateString(t("localeCode"), {
          day: "numeric",
          month: "short",
          year: "numeric",
        })}`;
      }
    };
    if (to === new Date().getTime()) {
      const findSuitableTimespan = presetIntervals.find(
        (o) => o.value === daysbetween + 1
      );

      setTimespan(
        findSuitableTimespan ?? {
          value: -1,
          label: customLabel(),
        }
      );
    } else {
      setTimespan({
        value: -1,
        label: customLabel(),
      });
    }

    const findIntervalOption = intervalsOptions.filter(
      (o) => o.maxDays >= daysbetween && o.minDays <= daysbetween
    );

    setIntervalOptions(findIntervalOption);
    setQueryOptions({
      interval: findSuitableInterval(daysbetween),
      fromDate: from,
      toDate: to,
    });
  };

  return (
    <WidgetWrapper
      callToAction={true}
      payedFeature={false}
      child={
        <Box w={"100%"} h={"100%"}>
          <Flex>
            <Flex gap={3} padding={"0px 0px 20px 0px"}>
              {timespan && (
                <Select
                  value={timespan}
                  className="selector"
                  options={timespanOptions}
                  onChange={handleTimespanChange}
                  getOptionLabel={(option) => option.label}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      minWidth: "200px", // Ersätt med önskad minsta bredd
                    }),
                  }}
                />
              )}
              {queryOptions.interval && (
                <Select
                  value={intervalOptions.find(
                    (option) => option.value === queryOptions.interval
                  )}
                  className="selector"
                  options={intervalOptions}
                  onChange={(e) => {
                    setQueryOptions({
                      ...queryOptions,
                      interval: e.value,
                    });
                  }}
                  getOptionLabel={(option) => option.label}
                />
              )}
              <DatePicker
                selectedDateRange={[queryOptions.fromDate, queryOptions.toDate]}
                onChange={(dateRange) => {
                  if (!dateRange[0] || !dateRange[1]) return;
                  updateQuery(dateRange[0].getTime(), dateRange[1].getTime());
                }}
              />
            </Flex>
          </Flex>
          {qrSettings && queryOptions ? (
            <HighChart
              multiple={multiple}
              removeLegend={removeLegend}
              selectedQR={[qrSettings]}
              queryOptions={queryOptions}
            />
          ) : (
            <Flex
              height={"400px"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              Välj en eller flera QR koder
            </Flex>
          )}
        </Box>
      }
    />
  );
};

export default CustomChart;
