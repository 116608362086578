import { Box, Grid, GridItem, Flex } from "@chakra-ui/react";
import WidgetWrapper from "../../../../components/Widgets/WidgetWrapper";

import ScoreWidget from "../../../../components/Widgets/ScoreWidget";

import qrScan from "../../../../content/icons/scan.svg";

import { useContext, useEffect, useState } from "react";
import { countoTotalLast30_7_1Days } from "../../../../calls";

import { UserContext } from "../../../../context";
import { useTranslation } from "react-i18next";

import CustomChart from "../../../../components/CustomChart";

const InfoQR = ({ qrSettings }) => {
  const { t } = useTranslation();

  const [state] = useContext(UserContext);
  const [scoreWidget, setScoreWidget] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await countoTotalLast30_7_1Days(
          () => state.user.getIdToken(),
          qrSettings.qrID
        );
        setScoreWidget(response.data.data[0]);
      } catch (error) {
        console.error("Error fetching QR scan data:", error);
      }
    };
    fetchData();
  }, [qrSettings.qrID, state.user]);

  return (
    <Grid
      display={["flex", "flex", "grid"]}
      flexDirection={"column"}
      color={"white"}
      padding={"0px 0px 20px 0px"}
      gap={"20px"}
      height={"100%"}
      width={"100%"}
    >
      <Grid gap="20px" width={"100%"}>
        <Flex
          gap="20px"
          width={"100%"}
          flexDirection={["column", "column", "row"]}
        >
          <WidgetWrapper
            payedFeature={false}
            child={
              <ScoreWidget
                text={t("qrScansToday")}
                index={
                  scoreWidget["count_last_day"]
                    ? scoreWidget["count_last_day"]
                    : 0
                }
                icon={qrScan}
              />
            }
          />
          <WidgetWrapper
            payedFeature={false}
            child={
              <ScoreWidget
                text={t("qrScansLatestX", { days: 7 })}
                index={
                  scoreWidget["count_last_7_days"]
                    ? scoreWidget["count_last_7_days"]
                    : 0
                }
                icon={qrScan}
              />
            }
          />
          <WidgetWrapper
            payedFeature={false}
            child={
              <ScoreWidget
                text={t("qrScansLatestX", { days: 30 })}
                index={
                  scoreWidget["count_last_30_days"]
                    ? scoreWidget["count_last_30_days"]
                    : 0
                }
                icon={qrScan}
              />
            }
          />
          <Box marginLeft={"auto"}>
            <WidgetWrapper
              payedFeature={false}
              child={
                <ScoreWidget
                  text={t("totalScans")}
                  index={qrSettings.scans ? qrSettings.scans : 0}
                  icon={qrScan}
                />
              }
            />
          </Box>
        </Flex>

        <GridItem>
          <CustomChart qrSettings={qrSettings} />
        </GridItem>
      </Grid>
    </Grid>
  );
};

export default InfoQR;
