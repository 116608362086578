import { Box, Grid, GridItem, Flex, Button } from "@chakra-ui/react";
import WidgetWrapper from "../../components/Widgets/WidgetWrapper";
import { useState } from "react";

import DataTablePrime from "../../components/DataTablePrime";

import { useTranslation } from "react-i18next";
import CustomChart from "../../components/CustomChart";

const Analys = () => {
  const { t } = useTranslation();

  const [selectedQR, setSelectedQR] = useState([]);
  const [runAnalysTool, setRunAnalysTool] = useState(false);
  const analysButton = true;

  const AnalyticTool = () => {
    return (
      <Grid gap={"20px"}>
        <GridItem>
          <CustomChart
            removeLegend={false}
            qrSettings={selectedQR}
            multiple={true}
          />
        </GridItem>
      </Grid>
    );
  };

  return (
    <Grid
      display={["flex", "flex", "grid"]}
      flexDirection={"column"}
      w={"100%"}
      color={"white"}
      gap={"20px"}
      width={"100%"}
    >
      {runAnalysTool ? (
        <>
          <Flex>
            <Button
              bg={"#5138ee"}
              color={"white"}
              marginRight={"auto"}
              fontSize={"14px"}
              onClick={() => setRunAnalysTool(false)}
            >
              {t("back")}
            </Button>
          </Flex>

          <AnalyticTool />
        </>
      ) : (
        <Flex
          width={"100%"}
          rounded={"lg"}
          flexDirection={"column"}
          gap={"10px"}
        >
          <WidgetWrapper
            noPadding={true}
            payedFeature={false}
            callToAction={true}
            child={
              <Box width={"100%"}>
                <DataTablePrime
                  setRunAnalysTool={setRunAnalysTool}
                  analysButton={analysButton}
                  row={8}
                  noRedirect={true}
                  selectBox={true}
                  selectedQR={selectedQR}
                  setSelectedQR={setSelectedQR}
                />
              </Box>
            }
          ></WidgetWrapper>
        </Flex>
      )}
    </Grid>
  );
};

export default Analys;
