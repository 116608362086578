import { useRef, useEffect, useState, useContext } from "react";
import Highcharts from "highcharts";
import { UserContext } from "../context/index";
import { Spinner, Flex, Box, Text } from "@chakra-ui/react";
import {
  countTotalEventsFromBigqueryByTime,
  countEventsfromBigqueryByTime,
} from "../calls";
import { useTranslation } from "react-i18next";

const getDateTimeLabelFormats = (interval) => {
  switch (interval) {
    case "YEAR":
      return { year: "numeric" };
    case "MONTH":
      return { year: "numeric", month: "short" };
    case "DAY":
      return { year: "numeric", month: "short", day: "numeric" };
    case "HOUR":
      return {
        hour: "numeric",
        minute: "2-digit",
      };
    case "MINUTE":
      return {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "2-digit",
      };
    default:
      return {};
  }
};

const HighChart = ({
  selectedQR,
  queryOptions,
  removeLegend,
  multiple = false,
}) => {
  const { t } = useTranslation();
  const [state] = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState([]);
  const [containData, setContainData] = useState(false);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [error, setError] = useState(null);

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (loading) return;
    if (state.stripeRole === "undefiend") {
      setLoading(false);
      return;
    }
    setLoading(true);
    setError(null);
    const fetchData = async () => {
      try {
        let data;
        if (selectedQR && queryOptions) {
          data = await countEventsfromBigqueryByTime(
            () => state.user.getIdToken(),
            queryOptions,
            multiple ? selectedQR[0] : selectedQR
          );
        } else {
          data = await countTotalEventsFromBigqueryByTime(
            () => state.user.getIdToken(),
            queryOptions
          );
          data = [{ data: data.reverse() }];
        }
        setChartData(data);
        setContainData(data.some((series) => series.data.length > 0));
      } catch (error) {
        setError(error);
        setContainData(false);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [selectedQR, state.stripeRole, state.user]);

  useEffect(() => {
    if (!chartData || !containData) return;

    Highcharts.chart(chartRef.current, {
      chart: {
        type: "spline",
        zoomType: "x",
      },
      title: {
        text: "",
      },
      tooltip: {
        animation: true,
        formatter: function () {
          const interval = queryOptions.interval;
          const date = new Date(this.x);
          const qrName = multiple ? this.point.series.userOptions.name : "";
          if (interval === "WEEK")
            return `${qrName} v${getWeekNumber(date)} </br> ${
              this.y > 0
                ? `<span style="font-weight: bold;">Scans: </span> ${this.y}`
                : ``
            }`;
          const formatOptions = getDateTimeLabelFormats(interval);
          const formatter = new Intl.DateTimeFormat(
            t("localeCode"),
            formatOptions
          );
          return `
          ${
            qrName
              ? `<span style="font-weight: bold">${qrName} <br/><br/></span>`
              : ""
          }${formatter.format(date)} <br/>${
            this.y > 0
              ? `<span style="font-weight: bold;">Scans: </span>${this.y}`
              : ""
          }`;
        },
      },
      time: {
        useUTC: true,
      },
      yAxis: {
        title: {
          text: t("amountScans"),
        },
        accessibility: {
          description: t("amountScans"),
        },
      },
      xAxis: {
        type: "datetime",
        tickInterval: queryOptions.interval === "DAY" && 24 * 3600 * 1000,
        scaleLabel: {
          display: true,
          labelString: "Date",
        },
        accessibility: {
          description: t("timeline"),
        },
        labels: {
          formatter: function () {
            const interval = queryOptions.interval;
            const date = new Date(this.value);
            if (interval === "WEEK") {
              const weekNumber = getWeekNumber(date);
              return `v${weekNumber}`;
            }
            const formatOptions = getDateTimeLabelFormats(interval);
            const formatter = new Intl.DateTimeFormat(
              t("localeCode"),
              formatOptions
            );
            return formatter.format(date);
          },
        },
      },
      plotOptions: {
        spline: {
          lineWidth: 4,
          states: {
            hover: {
              lineWidth: 5,
            },
          },
        },
        series: {
          marker: {
            enabled: false,
            states: {
              hover: {
                enabled: true,
              },
            },
          },
        },
      },
      series:
        chartData &&
        chartData.map((child) => {
          return {
            name: child.name,
            data: child.data.map(([x, y]) => ({
              x,
              y,
              marker: {
                enabled: y > 0,
              },
            })),
            showInLegend: removeLegend ? false : true,
          };
        }),
      accessibility: {
        enabled: true,
      },
    });
  }, [
    chartData,
    windowSize,
    containData,
    queryOptions.interval,
    removeLegend,
    t,
  ]);

  const getWeekNumber = (date) => {
    const d = new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    );
    const dayNum = d.getUTCDay() || 7;
    d.setUTCDate(d.getUTCDate() + 4 - dayNum);
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    return Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
  };

  return (
    <Box>
      <div
        style={{
          display: containData && !loading && !error ? "block" : "none",
          height: "500px",
        }}
        ref={chartRef}
      ></div>
      <Flex
        style={{ display: !containData || loading || error ? "flex" : "none" }}
        height={"500px"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        {loading ? (
          <Spinner size={"xl"} color={"#062B8E"} />
        ) : (
          <Text color={"black"}>{t("noScansFound")}</Text>
        )}
      </Flex>
    </Box>
  );
};

export default HighChart;
