import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Box,
  IconButton,
  Input,
  Popover,
  PopoverTrigger,
  PopoverContent,
} from "@chakra-ui/react";
import { CalendarIcon } from "@chakra-ui/icons";

const DatePickerComponent = ({ selectedDateRange, onChange }) => {
  const [dateRange, setDateRange] = useState(selectedDateRange); // Initialize with [null, null]
  const [isOpen, setIsOpen] = useState(false);

  const handleChange = (update) => {
    setDateRange(update);
    onChange(update);
  };

  return (
    <Popover
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      placement="bottom-start"
    >
      <PopoverTrigger>
        <IconButton
          aria-label="Pick a date"
          icon={<CalendarIcon />}
          onClick={() => setIsOpen(!isOpen)}
          variant="outline"
        />
      </PopoverTrigger>

      <PopoverContent width="auto" p={2}>
        <DatePicker
          selected={dateRange[0]}
          onChange={handleChange}
          startDate={dateRange[0]}
          endDate={dateRange[1]}
          selectsRange
          inline
        />
      </PopoverContent>
    </Popover>
  );
};

export default DatePickerComponent;
