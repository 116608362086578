import React, { useEffect, useState, useContext } from "react";
import { Text, Flex, Spinner } from "@chakra-ui/react";
import WidgetWrapper from "../../../components/Widgets/WidgetWrapper";
import { UserContext } from "../../../context/index.js";
import { useTranslation } from "react-i18next";
import { userPaymentIntents } from "../../../calls.js";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const TransactionHistory = ({ hasFreeRole }) => {
  const { t } = useTranslation();
  const [state] = useContext(UserContext);
  const [loadingPayment, setLoadingPayment] = useState(true);
  const [paymentHistory, setPaymentHistory] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userPaymentIntentsHistory = await userPaymentIntents(
          () => state.user.getIdToken(),
          process.env.REACT_APP_PRODUCT_ID
        );
        setPaymentHistory(userPaymentIntentsHistory.data.paymentIntents || []);
      } catch (error) {
        console.error("Error fetching payment history:", error);
      } finally {
        setLoadingPayment(false);
      }
    };

    fetchData();
  }, [state]);

  const formatCurrency = (value, currency) => {
    return new Intl.NumberFormat(currency === "sek" ? "sv-SE" : "en-GB", {
      style: "currency",
      currency: currency,
    }).format(value / 100);
  };

  const formatDate = (timestamp) => {
    return new Date(timestamp * 1000).toLocaleDateString("sv-SE", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  if (loadingPayment) {
    return (
      <Flex
        height={"100%"}
        width={"100%"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Spinner size={"xl"} color={"#062C8E"}></Spinner>
      </Flex>
    );
  }

  return (
    <Flex
      gap={4}
      overflow={"hidden"}
      flexDirection={["column", "column", "row"]}
    >
      <Flex
        width={"100%"}
        overflowX={"scroll"}
        rounded={"lg"}
        flexDirection={"column"}
        gap={"10px"}
      >
        <DataTable
          tableStyle={{
            overflow: "scroll",
            fontSize: "14px",
          }}
          width={"100%"}
          stripedRows
          value={paymentHistory}
          paginator
          rows={8}
          emptyMessage={t("no_payment_history_available")}
        >
          <Column field="id" header={t("transaction_id")} sortable></Column>
          <Column
            field="amount"
            header={t("amount")}
            body={(rowData) => formatCurrency(rowData.amount, rowData.currency)}
            sortable
          ></Column>
          <Column field="currency" header={t("currency")} sortable></Column>
          <Column
            field="created"
            header={t("date")}
            body={(rowData) => formatDate(rowData.created)}
            sortable
          ></Column>
          <Column
            field="status"
            header={t("status")}
            sortable
            body={(rowData) => (
              <span
                style={{
                  color: rowData.status === "succeeded" ? "#28a745" : "#ee3838",
                }}
              >
                {rowData.status}
              </span>
            )}
          ></Column>
        </DataTable>
      </Flex>
    </Flex>
  );
};

export default TransactionHistory;
