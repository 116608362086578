import { Flex, Text, Divider, Center, Link, Box } from "@chakra-ui/react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { sendNewEmailVerification } from "./index";
import { t } from "i18next";
import LanguageSelector from "../../components/LanguageSelector";

const Create = () => {
  const navigator = useNavigate();
  const location = useLocation();

  if (!location.state || !location.state.email || !location.state.password) {
    // Handle the case where location.state is not defined or missing properties
    // You might want to redirect the user or show an error message
    navigator("/");
    return;
  }

  return (
    <Flex p={6} width={"100%"}>
      <Box
        position={"absolute"}
        top={0}
        right={0}
        margin={"20px"}
        background={"white"}
      >
        <LanguageSelector loginPage={true} />
      </Box>
      <Flex
        color={"white"}
        flexDirection={"column"}
        gap={"12px"}
        width={"100%"}
      >
        <Text fontWeight={"bold"} fontSize={"18px"}>
          {t("verifyAccount")}
        </Text>
        <Text fontSize={"14px"}>
          {t("verfiyAccountSubText")} {location.state.email}.
        </Text>

        <Text fontSize={"14px"}>
          {t("verifyAccountNoMail")}{" "}
          <Link
            fontWeight={"bold"}
            onClick={async () => {
              const verify = await sendNewEmailVerification(
                location.state.email,
                location.state.password
              );
              if (verify.status === 200) {
                toast.success(
                  `${t("popupVerfiyAccount")} ${location.state.email}`
                );
              } else {
                toast.error(verify.error);
              }
            }}
          >
            {t("klicka här.")}
          </Link>
        </Text>
        <Text color={"#718096"} fontSize={"12px"}>
          {t("verfiyAccountSubWarning")}
        </Text>
        <Center w={["100%"]} h={["40px", "40px", "auto"]}>
          <Divider />
        </Center>

        <Flex alignItems={"center"} gap={2} color={"#718096"} fontSize={"12px"}>
          <Text>{t("alreadyRegistrate")} </Text>

          <Link
            color={"white"}
            onClick={() =>
              navigator("/login", { state: { removeGoogle: true } })
            }
            fontWeight={"bold"}
          >
            {t("login-here")}
          </Link>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Create;
